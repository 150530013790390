import React from 'react'
import { Layout } from '@/components/Layout'
import { SEO } from '@/components/SEO'

export default function NotFound() {
  return (
    <Layout>
      <SEO title="Page not found" description="The page you were looking for was not found." />
      <section className="min-h-[300px] mt-16  mb-6">
        <div className="sm:max-w-7xl px-0 sm:px-2 md:px-4 sm:py-4 sm:mx-auto md:space-x-10 lg:px-8">
          <div className="border-l-8 border-l-jump-red border border-y-0 border-r-0 mb-10 pt-3">
            <div className="text-4xl md:text-5xl xl:text-6xl font-bold pl-7">
              <h1>Page not found</h1>
            </div>
            <div className="text-dark-gray text-xl  pl-7 py-4">
              We can't find the page you're looking for.
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
